<nav class="header">
    <div class="menu">
        <img src="assets/images/logo.svg" alt="" class="logo"  [routerLink]="['/home']" routerLinkActive="menu-desktop__item--active" [queryParams]="{q: 'home'}" (click)="goToHome()"  >
        <div class="menu-mobile__icon" (click)="rightMenuOpened = !rightMenuOpened">
            <img class="menu-mobile__icon--close" src="assets/images/icons/close-menu.svg" alt="">
            <img class="menu-mobile__icon--open" src="assets/images/icons/menu.svg" alt="">
        </div>
        <ul class="menu-desktop">
            <li class="menu-desktop__item" [routerLink]="['/home']" routerLinkActive="menu-desktop__item--active" [queryParams]="{q: 'home'}" (click)="goToHome()"  >Home</li>
            <li class="menu-desktop__item" [routerLink]="['/home']" [queryParams]="{q: 'services'}" (click)="goToServices()">Services</li>
            <li class="menu-desktop__item" [routerLink]="['/home']" [queryParams]="{q: 'testimonials'}" (click)="goToTestimonials()">Testimonials</li>
            <li class="menu-desktop__item" [routerLink]="['/about']" routerLinkActive="menu-desktop__item--active" [queryParams]="{q: 'about'}" (click)="goToAbout()">About us</li>
            <li class="menu-desktop__item" [routerLink]="['/home']" [queryParams]="{q: 'contact'}" (click)="goToContact()">Contact us</li>
            <li class="menu-desktop__item">
                <a href="https://www.facebook.com/profile.php?id=100029381353551" target="_blank">
                    <img src="assets/images/face.png" alt="facebook">
                </a>
            </li>
            <li class="menu-desktop__item">
                <a href="https://www.instagram.com/sacleaningservicesinc/" target="_blank">
                    <img src="assets/images/insta.png"alt="instagram">
                </a>
            </li>
            <li class="menu__item">
                <a href="https://www.yelp.com/biz/sa-cleaning-services-columbus-3" target="_blank">
                    <img src="assets/images/yelp.png" alt="yelp" >
                </a>
            </li>
        </ul>
    </div>
    <div class="menu-mobile" [ngClass]="{'menu-mobile--open' : rightMenuOpened}">
		<ul class="menu-mobile__menu" (click)="rightMenuOpened = !rightMenuOpened">
            <li class="menu__item" [routerLink]="['home']" routerLinkActive="menu__item--active" [queryParams]="{q: 'home'}" (click)="goToHome()"  >Home</li>
            <li class="menu__item" [routerLink]="['/home']" [queryParams]="{q: 'services'}" (click)="goToServices()">Services</li>
            <li class="menu__item" [routerLink]="['/home']" [queryParams]="{q: 'testimonials'}" (click)="goToTestimonials()">Testimonials</li>
            <li class="menu__item" [routerLink]="['/about']" routerLinkActive="menu__item--active" [queryParams]="{q: 'about'}" (click)="goToAbout()" >About us</li>
            <li class="menu__item" [routerLink]="['/home']" [queryParams]="{q: 'contact'}" (click)="goToContact()">Contact us</li>
            <li class="menu__item">
                <a href="https://www.facebook.com/profile.php?id=100029381353551" target="_blank">
                    <img src="assets/images/face.pngg" alt="facebook">
                </a>
            </li>
            <li class="menu-desktop__item">
                <a href="https://www.instagram.com/sacleaningservicesinc/" target="_blank">
                    <img src="assets/images/insta.png"alt="instagram">
                </a>
            </li>
            <li class="menu__item">
                <a href="https://www.yelp.com/biz/sa-cleaning-services-columbus-3" target="_blank">
                    <img src="assets/images/yelp.png" alt="yelp">
                </a>
            </li>
		</ul>
		<div class="menu-mobile__overlay" *ngIf="rightMenuOpened" (click)="rightMenuOpened = false"></div>
    </div>
</nav>