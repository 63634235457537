
<style>
        @media  (max-width: 768px) {


    .flex {
        display: block!important;
        padding: 60px 0;
        text-align: center;
    }
 img.social {
    margin-right: 12px;
    margin-left: 12px;
    margin-top: 5px;
}

}
</style>
<footer>

    <div class="footer-wrapper">
        <div class="flex flex--space-between page-wrapper">
            <div class="col">
                <ul>
                    <li class="menu-desktop__item">Home</li>
                    <li class="menu-desktop__item" >Services</li>
                    <li class="menu-desktop__item" >Testimonials</li>
                </ul>
            </div>
            <div class="col">
                <ul>
                    <li class="menu-desktop__item">About us</li>
                    <li class="menu-desktop__item">Contact us</li>
                </ul>
            </div>
            <div class="col">
                <img src="assets/images/form-logo.png" class="logo" alt="">
            </div>
            <div class="col">
                <ul class="align-center">
                    <li>SA Cleaning</li>
                    <li>marketing@sacleaningservicessa.com</li>
                    <li>+1(614)354-9563</li>
                </ul>
            </div>
            <div class="col">
                <ul>
                    <li>Follow</li>
                    <li><a href="https://www.facebook.com/profile.php?id=100029381353551" target="_blank"><img src="assets/images/facebook_green.png" class="social" alt=""></a> <a href="https://www.instagram.com/sacleaningservicesinc/" target="_blank"> <img src="assets/images/insta_green.png" alt="" class="social"></a></li>
                </ul>
            </div>
        </div>
        <div class="flex final ">
            <p><small>{{currentYear}}  SA Cleaning. All rights reserved</small></p>
        </div>
    </div>
</footer> 